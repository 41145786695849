import React from 'react'
import { NavLink } from 'react-router-dom'
import {
    HomeIcon,
    PaintBrushIcon,
    TrophyIcon,
    CheckBadgeIcon,
    SparklesIcon,
    UsersIcon,
    UserGroupIcon,
    ArrowUturnLeftIcon,
    Cog6ToothIcon
} from '@heroicons/react/24/outline'
import logo from '../assets/images/logo.png'

const navigation18 = [
    {
        id: 1,
        name: '',
        group: [
            { name: '数据面板',   href: '/dash',      icon: HomeIcon, current: false },
            { name: '参赛作品',   href: '/entries',   icon: PaintBrushIcon, current: false },
            { name: '初审通过',   href: '/finalists', icon: SparklesIcon,   current: false },
            { name: '复审通过',   href: '/awards',    icon: CheckBadgeIcon, current: false },
            { name: '晋级省赛',   href: '/advances',  icon: TrophyIcon,     current: false },
        ]
    }
]

const navigation19 = [
    {
        id: 'entry',
        name: '',
        group: [
            { name: '数据面板',   href: '/dash',      icon: HomeIcon, current: false },
            { name: '参赛作品',   href: '/entries',   icon: PaintBrushIcon, current: false },
            { name: '复审',   href: '/awards',    icon: CheckBadgeIcon, current: false },
            { name: '晋级省赛',   href: '/advances',  icon: TrophyIcon,     current: false },
            { name: '退回申请',   href: '/applyReturn',  icon: ArrowUturnLeftIcon,     current: false },
        ]
    },
    {
        id: 'member',
        name: '成员',
        group: [
            { id: 1, name: '评委管理',       href: '/members', icon: UsersIcon, current: false },
            { id: 2, name: '指导老师管理',       href: '/teachers', icon: UserGroupIcon, current: false },
        ]
    },
    {
        id: 'setting',
        name: '设置',
        group: [
            { id: 1, name: '校内截稿时间设置',       href: '/settings/deadline', icon: Cog6ToothIcon, current: false },
        ]
    }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Sidebar({sidebarCollapse, setSidebarCollapse}) {
    const eventid = localStorage.getItem('inst_eventid')
    const showMenu = () => {
        if (eventid === '648d35b8-0a00-0802-37db-e2d284846ede') {
            return navigation18
        } else if (eventid === '6704e69d-0000-0000-34d4-227ddca46fc6') {
            return navigation19
        }
    }
    
    return (
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-blue-600 px-6">
            <div className="flex h-16 shrink-0 items-center">
                <img
                    className="h-8 w-auto"
                    src={logo}
                    alt="Your Company"
                />
                <span className={sidebarCollapse ? 'sr-only ease-out duration-300' : 'ml-2 text-white text-lg text-bold'}>好创意大赛</span>
            </div>
            
            <nav className="flex flex-1 flex-col">
                <ul className="flex flex-1 flex-col gap-y-7">
                    { showMenu().map((item) => (
                        <li key={item.id}>
                            { item.name &&  <div className="text-xs font-semibold leading-6 text-blue-200">{ item.name }</div> }
                            <ul className="-mx-2 mt-2 space-y-1">
                                { item.group.map((sub) => (
                                    <li key={sub.name}>
                                        <NavLink
                                            to={sub.href}
                                            className={({ isActive }) =>
                                                isActive ?
                                                "hover:text-white hover:bg-blue-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold bg-blue-700 text-white" :
                                                "hover:text-white hover:bg-blue-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-blue-200"
                                            }
                                        >
                                            <sub.icon 
                                                className={classNames(
                                                    sub.current ? 'text-white' : 'text-blue-200 group-hover:text-white',
                                                    'h-6 w-6 shrink-0'
                                                )}
                                                aria-hidden="true"
                                                title={sub.name}
                                            />
                                            <span className={sidebarCollapse ? 'sr-only ease-out duration-300' : ''}>{sub.name}</span>
                                        </NavLink>
                                    </li>
                                )) }
                            </ul>
                        </li>
                    )) }
                </ul>
            </nav>
        </div>
    )
}