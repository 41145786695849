
import Api from '../utils/api'
import { calculateAge } from '../utils/utility'

import logo from '../assets/images/logo.png'

export const dataPersonFormatter = (person) => {
  return {
    ...person,
    key: person.id,
    fullname: person.fullname ? person.fullname : person.lastname + person.firstname,
    avatarurl: person.avatarurl !== '' ? person.avatarurl : logo,
    logourl: person.logourl !== '' ? person.logourl : logo,
    age: calculateAge(person.birthdate),
    birthdate: new Date(person.birthdate * 1000).toLocaleDateString()
  }
}

export const getProfile = async () => {
  return await Api.get('/me/profile').then((res) => {
    return dataPersonFormatter(res)
  })
}

export const getEntries = async (params) => {
  return await Api.get('/me/entries', params).then((res) => {
    return res
  })
}

export const getEntry = async (entryid) => {
  return await Api.get(`/me/entries/${entryid}`).then((res) => {
    return res
  })
}

export const getEntriesStats = async (params) => {
  return await Api.get('/me/entries/stats', params).then((res) => {
    return res
  })
}

export const returnEntry = async (entryid, params) => {
  return await Api.put(`/me/entries/${entryid}/return`, params).then((res) => {
    return res
  })
}

export const rejectEntry = async (entryid) => {
  return await Api.put(`/me/entries/${entryid}/reject`).then((res) => {
    return res
  })
}

export const postFinalist = async (entryid) => {
  return await Api.post('/me/finalists', { entryid }).then((res) => {
    return res
  })
}

export const getFinalists = async (params) => {
  return await Api.get('/me/finalists', params).then((res) => {
    return res
  })
}

export const postAward = async (finalistid) => {
  return await Api.post('/me/awards', { finalistid }).then((res) => {
    return res
  })
}

export const getAdwards = async (params) => {
  return await Api.get('/me/awards', params).then((res) => {
    return res
  })
}

export const putAdvance = async (awardid) => {
  return await Api.put(`/me/awards/${awardid}/advance`).then((res) => {
    return res
  })
}

export const getCategories = async (params = {}) => {
  return await Api.get('/categories', params).then((res) => {
    return res
  })
}

export const getEnums = async () => {
  return await Api.get('/settings/enums').then((res) => {
    return res
  })
}

export const signin = async (params) => {
  return await Api.get('/auth/signin', params).then((res) => {
    return res
  })
}

// 批量初审
export const batchFinalistsApi = async (entryids) => {
  return await Api.post('/me/finalists/batchAudit', { entryids }).then((res) => {
    return res
  })
}

// 批量复审
export const batchAwardsApi = async (finalistids) => {
  return await Api.post('/me/awards/batchAudit', { finalistids }).then((res) => {
    return res
  })
}

// 批量晋级省赛
export const batchAdvanceApi = async (awardids) => {
  return await Api.put('/me/awards/batchAdvance', { awardids }).then((res) => {
    return res
  })
}

// 院校端-撤回上一级
export const awardCancelApi = async (awardid) => {
  return await Api.put(`/me/awards/${awardid}/cancel`).then((res) => {
    return res
  })
}

// 院校评委列表
export const getMembers = async (institutionid, params) => {
  return await Api.get(`/me/institutions/${institutionid}/judges`, params).then((res) => {
    return res
  })
}

// 新增院校评委
export const postMembers = async (data) => {
  return await Api.post('/me/institutions/judge', data).then((res) => {
    return res
  })
}

// 修改评委-分类
export const postMembersCateApi = async (collaboratorid, data) => {
  return await Api.put(`/collaborators/${collaboratorid}/member`, data).then((res) => {
    return res
  })
}

// 删除院校评委
export const deleteMember = async (institutionid, judgeid) => {
  return await Api.delete(`/me/institutions/${institutionid}/judge/${judgeid}`).then((res) => {
    return res
  })
}

// 教师列表
export const teacherListApi = async (institutionid, params) => {
  return await Api.get(`/institutions/${institutionid}/teachers`, params).then((res) => {
    return res
  })
}

// 新增教师
export const postTeacherApi = async (institutionid, params) => {
  return await Api.post(`/institutions/${institutionid}/teacher`, params).then((res) => {
    return res
  })
}

// 编辑教师
export const putTeacherApi = async (institutionid, params) => {
  return await Api.put(`/institutions/${institutionid}/teacher`, params).then((res) => {
    return res
  })
}

// 删除教师
export const delTeacherApi = async (institutionid, teacherid) => {
  return await Api.delete(`/institutions/${institutionid}/teachers/${teacherid}`).then((res) => {
    return res
  })
}

// 申请退回列表
export const applyReturnListApi = async (params) => {
  return await Api.get('/me/entries/return', params).then((res) => {
    return res
  })
}

// 设置院校截稿时间
export const setCloseTimeApi = async (institutionid, params) => {
  return await Api.put(`/institutions/${institutionid}/closetime`, params).then((res) => {
    return res
  })
}